
import { defineComponent } from 'vue';
import InfoMessage from './InfoMessage.vue';
import RangeModeSlider from '~/components/RangeSlider/RangeModeSlider.vue'
import { deviceTypes } from '@/utils/constants';

export default defineComponent({
  name : 'DeviceSelector',
  components:{
    InfoMessage,
    RangeModeSlider
  },
  data(){
    return{
        desktopPercent: 50,
        mobilePercent: 50,
        type: deviceTypes.ALL_PLATFORMS,
		deviceViabilityInfo: this.$t('DEVICE_VIABILITY_INFO'),
        deviceTypes: deviceTypes
    }
  },
  methods:{
    change(type: any){
        this.type = type
        if(type == deviceTypes.ONLY_DESKTOP){
            this.mobilePercent = 0
            this.desktopPercent = 100
        }
        else if(type == deviceTypes.ONLY_MOBILE){
            this.mobilePercent = 100
            this.desktopPercent = 0
        }
        else{
            this.mobilePercent = 50
            this.desktopPercent = 50
        }
        this.$emit('update')
    },
    sliderUpdate(event: any){
        this.desktopPercent = event[0]
        this.mobilePercent = event[1]
        this.$emit('update')
    },
    setDeviceType(type: any) {
        this.type = type
    }
  }
})
