import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LanguageSelector" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectionBox = _resolveComponent("SelectionBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectionBox, {
      text: _ctx.$t('languages_spanish'),
      selected: _ctx.language == 'es',
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.language = 'es'))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('~/assets/img/Flags/es.svg')
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["text", "selected"]),
    _createVNode(_component_SelectionBox, {
      text: _ctx.$t('languages_Portuguese'),
      selected: _ctx.language == 'pt',
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.language = 'pt'))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('~/assets/img/Flags/pt.svg')
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["text", "selected"]),
    _createVNode(_component_SelectionBox, {
      text: _ctx.$t('languages_Italian'),
      selected: _ctx.language == 'it',
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.language = 'it'))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('~/assets/img/Flags/it.svg')
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    }, 8, ["text", "selected"]),
    _createVNode(_component_SelectionBox, {
      text: _ctx.$t('languages_english'),
      selected: _ctx.language == 'en',
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.language = 'en'))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('~/assets/img/Flags/en.svg')
        }, null, 8, _hoisted_5)
      ]),
      _: 1
    }, 8, ["text", "selected"])
  ]))
}