
    import { defineComponent } from 'vue';  
    import store from '@/store'
    import StatusCircle from '@/components/StatusCircle.vue';
    import NeuroProgress from '@/components/NeuroProgress.vue';
    import ProgressBar from '@/components/ProgressBar.vue';
    import ChartNeuro from '@/components/ChartNeuro.vue';
    import ProgressCircle from '~/components/ProgressCircle/ProgressCircle.vue';

    export default defineComponent({
        name : 'WatNeuroMonitor',
        components:{
            StatusCircle,
            NeuroProgress,
            ProgressBar,
            ProgressCircle,
            ChartNeuro
        },
        data:()=>{
            return{
                selectedTab: 0,
                neuroData: {
                    globalIndicator: 65,
                    brand_recognition: 0,
                    brand_contribution: 0,
                    attention: 0,
                    activation: 0,
                    positivity: 0,
                    negativity: 0,
                    emotionalImpact: 0,
                    rejection: 0,
                    attributes:{"Sostenibilidad":89,"Desprecio":71,"Calidad":45,"Interés":39, "Indiferencia":12},
                    timeFrames:[{
                        emotionalImpact:10,
                        attention:15,
                        timeFrame:200
                    },
                    {
                        emotionalImpact:10,
                        attention:15,
                        timeFrame:1200
                    },
                    {
                        emotionalImpact:10,
                        attention:15,
                        timeFrame:2200
                    },
                    {
                        emotionalImpact:10,
                        attention:15,
                        timeFrame:3200
                    },
                    {
                        emotionalImpact:35,
                        attention:87,
                        timeFrame:4200
                    },
                    {
                        emotionalImpact:100,
                        attention:23,
                        timeFrame:5200
                    },
                    {
                        emotionalImpact:40,
                        attention:95,
                        timeFrame:6200
                    },
                    {
                        emotionalImpact:30,
                        attention:45,
                        timeFrame:7200
                    },
                    {
                        emotionalImpact:10,
                        attention:15,
                        timeFrame:8200
                    }]
                }
            }
        },
        computed:{
            attentionData(){
                const data = this.neuroData
                return data.timeFrames.map((item: any) => item.attention);
            },
            emotionalImpactData(){
                const data = this.neuroData
                return data.timeFrames.map((item: any) => item.emotionalImpact);
            },
            timeFramesData(){
                const data = this.neuroData
                return data.timeFrames.map((item: any) => item.timeFrame);
            }
        },
        methods:{
            progressClass(percent: any) {
                if(percent < 25) return "red"
                else if(percent < 50) return "yellow"
                else if(percent < 75) return "green"
                return "intenseGreen"
            },
            async fetchNeuroData(){
                await store.dispatch('fetchNeuroMonitor', {
                    contractId: this.$route.params.id,
                })
                .then((data) => {
                    this.neuroData = data
                })
            }
        }
    })
  