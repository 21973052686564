import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-465d0442"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProgressBar" }
const _hoisted_2 = { class: "ProgressBar--Wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressNum = _resolveComponent("ProgressNum")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideNumbers)
      ? (_openBlock(), _createBlock(_component_ProgressNum, {
          key: 0,
          count: _ctx.count,
          total: _ctx.total
        }, null, 8, ["count", "total"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle({ width: _ctx.getProgress() + '%' })
      }, null, 4)
    ])
  ]))
}