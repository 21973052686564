
import ProgressCircle from '~/components/ProgressCircle/ProgressCircle.vue';
export default {
  name: "NeuroProgress",
  components:{
    ProgressCircle
  },
  props: {
    title: String,
    text: String,
    percent: {
      type: Number,
      required: true
    },
    icon: String,
    invertColors:{
      type: Boolean,
      default: false
    }
  },
};
