import ApiService from '@/service'

  const state = {
    test:{
      test:{},
      testTasks:[]
    },
    reportsList:[],
    reportTab: 0
  }

  const getters = {
    test(state: any){
      return state.test.test
    },
    testTasks(state: any){
      return state.test.testTasks
    },
    reportsList(state: any){
      return state.reportsList
    },
    reportTab(state: any){
      return state.reportTab
    }
  }

  const actions = {
    async getPublicFilterQuestions(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/public/test/' + payload.testId + '/filterQuestions', null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getFilterQuestions(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/test/' + payload.testId + '/filterQuestions', null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getPublicFilterQuestionsV3(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/public/test/' + payload.testId + '/filterQuestionsv3', null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getFilterQuestionsV3(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/test/' + payload.testId + '/filterQuestionsv3', null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getTest(context: any, payload:any) {
      const endPoint = isNaN(payload.testId) ? '/public/test/' : '/test/'
      return ApiService
        .get(endPoint + payload.testId,null)
        .then(({data}: any) => {
          context.commit('setStateTest',data)
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async getTestNavigationRules(context: any, payload: any){
      const endPoint = isNaN(payload.testId) ? '/test/public/' : '/test/'
      return ApiService
        .get(endPoint + payload.testId + '/navigationRules',null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getPublicTest(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/public/test/'+payload.testId+'/data', null)
        .then(({data}: any) => {
          context.commit('setStateTest',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getPublicCompanyQuestions(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/public/test/' + payload.testId + '/companyAdditionalQuestions', null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getCompanyQuestions(context: any, payload:any) {
      return ApiService
        .get('/clientDashboard/test/' + payload.testId + '/companyAdditionalQuestions', null)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getBasicReport(context: any, payload:any) {
      return ApiService
        .get('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/basicReport', payload.data)
    },
    async getBasicReportV3(context: any, payload:any) {
      return ApiService
        .get('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/basicReportv3', payload.data)
    },
    async getReport(context: any, payload:any) {
      return ApiService
        .get('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/report/'+payload.reportId, payload.data)
    },
    async getAllReports(context: any, payload:any) {
      return ApiService
        .get('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/reports', payload.data)
    },
    async getDashboardQuestion(context: any, payload:any) {
      return ApiService
        .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardv2', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getDashboardQuestionV3(context: any, payload:any) {
      const payloadData : string = JSON.stringify(payload.data)
      let payloadData2 = JSON.parse(payloadData)
      const xFilter = payloadData2.yDimensions
      payloadData2.xFilter = xFilter
      delete payloadData2.yDimensions
      return ApiService
        .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardv3', payloadData2)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getDashboardQuestionText(context: any, payload:any) {
      return ApiService
        .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardTextv2', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getDashboardQuestionHeatMap(context: any, payload:any) {
      return ApiService
        .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardHeatmapv2', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getDashboardQuestionUx(context: any, payload:any) {
      return ApiService
        .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardUxDatav2', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getDashboardQuestionClickTest(context: any, payload: any){
      return ApiService
      .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardClickTestDatav2', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return response;
      })
    },
    async getDashboardDefaultQuestion(context: any, payload:any) {
      return ApiService
        .get('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/task/'+payload.taskNum+"/question/"+payload.questionId+"/defaultVisualization", null)
    },

    async saveReport(context: any, payload:any) {
      return ApiService
        .put('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/report/'+payload.reportId, payload.data)
    },
    async deleteReport(context: any, payload:any) {
      return ApiService
        .delete('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/report/'+payload.reportId, null)
        .then(({data}: any) => {
          return data;
        })
    },
    async createNewReport(context: any, payload:any) {
      return ApiService
        .post('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/report', payload.data)
        .then(({data}: any) => {
          return data;
        })
    },
    async fetchCombinedExcel(context: any, payload:any) {
      return ApiService
      .get('/results'+(isNaN(payload.testId) ? '/public/' : '/')+payload.testId+'/combinedExcel', null,{
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'authToken':ApiService.getConfig().token()
        },
        responseType: 'blob',
      })
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchAggregatedExcel(context: any, payload:any) {
      return ApiService
      .post('/results/'+payload.testId+'/aggregatedExcel', payload.data,{
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'authToken':ApiService.getConfig().token()
        },
        responseType: 'blob',
      })
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async loadedExcelAggregated(context: any, payload:any) {
      return ApiService
      .post('/results/'+payload.testId+'/report/'+payload.reportId+'/generate', payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
    },
    async checkExcelAggregatedState(context: any, payload:any) {
      return ApiService
      .get('/results/'+payload.testId+'/excelAggregatedState')
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
    },
    async savePersonalization(context: any, payload:any) {
      return ApiService
      .put('/clientDashboard'+(isNaN(payload.testId)?'/public/':'/')+'test/'+payload.testId+'/personalization/'+payload.reportId, payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
    },
    async newWatMonitorPassword(context: any, payload:any) {
      return ApiService
        .post('/monitor/' + payload.testId + '/newPassword', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async validateWatMonitorPassword(context: any, payload:any) {
      return ApiService
        .post('/monitor/'+payload.testId+'/passwordValidation', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createFilterVariable(context: any, payload: any) {
      return ApiService
        .post('/clientDashboard/test/' + payload.testId + '/variable', payload.variable)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchFilterVariables(context: any, payload: any) {
      return ApiService
        .get('/clientDashboard/test/' + payload.testId + '/variables')
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async removeFilterVariable(context: any, payload: any) {
      return ApiService
        .delete('/clientDashboard/variable/' + payload.variableId)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchNeuroMonitor(context: any, payload: any) {
      return ApiService
        .get('/clientSuite/watNeuro/' + payload.contractId + "/data" )
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    }
  }

  const mutations = {
    setStateTest(state: any,data: any){
      state.test = data;
    },
    updateReportsList(state: any, reportList: any){
      state.reportsList = reportList
    },
    addReportToReportsList(state: any, report: any){
      state.reportsList.push(report)
    },
    removeReportFromReportsList(state: any, reportIndex: any){
      state.reportsList.splice(reportIndex,1)
    },
    updateReportTab(state: any, index: any){
      state.reportTab = index
    }
  }

export default{
  actions,
  state,
  getters,
  mutations
}
