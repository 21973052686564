
import * as _ from "lodash";
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import store from '@/store'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import SelectionBox from '@/components/StudyCreation/SelectionBox.vue'
import InfoMessage from '@/components/StudyCreation/InfoMessage.vue'
import PriceStep4 from '@/components/StudyCreation/PriceStep4.vue'
import Button from '@/components/Button.vue'
import { viabilityActivities } from '@/utils/viability'

import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name : 'Step4WatLab',
  components:{
    StudyCreationBase,
    Separation,
    SelectionBox,
    InfoMessage,
    PriceStep4,
    Button
  },
  setup() {
  const router = useRouter()
  const route = useRoute()

  return {
    router,
    route
  }
},
  props:{
    STEP:[String, Number]
  },
  computed:{
    ...mapGetters([
      'WatLabPlans',
      'clientCreditsPlan',
      'countries'
    ]),
    activitiesAllowed():any{
      const vm = this
      let activitiesAllowed = this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.activityTypes
      return activitiesAllowed == undefined ? [] : activitiesAllowed
    },
    planSupportType():any{
      const vm = this
      return this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.supportType
    },
    planParticipants():any{
      const vm = this
      return this.WatLabPlans.filter(function(wlp: any, index: any){
        if(wlp.plan == vm.watLabData.planName) return wlp
      })[0]?.maxActiveParticipants
    },
    planGradientBg():any{
      if(this.watLabData.planName == 'GOLD') return 'linear-gradient(90deg, rgba(226, 211, 75, 0) 0.21%, rgba(226, 211, 75, 0.19) 99.83%)'
      else if(this.watLabData.planName == 'SILVER') return 'linear-gradient(90deg, rgba(192, 192, 192, 0) 0.21%, silver 99.83%)'
      else return 'linear-gradient(90deg, rgba(226, 211, 75, 0) 0.21%, rgba(212, 139, 98, 0.19) 99.83%)'
    },
    countryLang():any{
      const countries: any = this.countries
      const elementId = this.watLabData.country.elementId
      if(countries == undefined) return 'es'
      return countries.filter(function(country: any){return country.id == elementId})[0].secondaryValue.toLowerCase()
    }
  },
  data(){
    return{
      watLabData: {
        planName:'',
        duration:1,
        price:0,
        urlToken: '',
        language: 'es',
        country: {elementId: 0},
        usersOrigin:'WAT',
        dateFrom:'11 de octubre de 2023',
        dateTo: '11 de octubre de 2023'
      },
      error: false,
      errorMessage:'',
      viabilityActivities: viabilityActivities
    }
  },
  methods:{
    async fetchWatLab(){
      await store.dispatch('fetchWatLabConfiguration',{contractId:this.route.params.id}).then((data) => {
        this.watLabData.duration = data.duration != undefined ? data.duration : this.watLabData.duration
        this.watLabData.planName = data.planName != undefined ? data.planName : this.watLabData.planName
        this.watLabData.price = data.price != undefined ? data.price : this.watLabData.price
        this.watLabData.urlToken = data.urlToken != undefined ? data.urlToken : this.watLabData.urlToken
        this.watLabData.language = data.language != undefined ? data.language : this.watLabData.language
        this.watLabData.country = data.country != undefined ? data.country : this.watLabData.country
        this.watLabData.usersOrigin = data.usersOrigin != undefined ? data.usersOrigin : this.watLabData.usersOrigin
        this.watLabData.dateFrom = data.dateFrom != undefined ? data.dateFrom : this.watLabData.dateFrom
        this.watLabData.dateTo = data.dateTo != undefined ? data.dateTo : this.watLabData.dateTo
      })
    },
    async step4Confirmation(){
      let vm = this
      await store.dispatch('confirmationWatLab',{contractId:this.route.params.id}).then((data) => {
        Swal.fire({
          title:vm.$t('suite_swal_proccess_completed'),
          icon:"success",
          customClass:{popup:"swal-customSuccess"}
        })
        this.router.push({name:'dashboard'})
      })
      .catch((response)  => {
        Swal.fire({
          title:vm.$t('suite_swal_not_enough_credits'),
          icon:"warning",
          customClass:{popup:"swal-customWarning"}
        })
      })
    },
    async fetchPlansData(){
      await store.dispatch('fetchWatLabPlans')
    },
    getTitleNum(num: any){
      if(this.watLabData.planName == 'MULTICOMMUNITY'){
        return num - 1
      }
      return num
    }
  },
  async created(){
    await this.fetchPlansData()
    await this.fetchWatLab()
  },
  async mounted(){
    $("html, body").animate({ scrollTop: 0 }, 400);
  }
})
