import { createRouter, createWebHistory } from 'vue-router'
import { VueCookieNext } from 'vue-cookie-next'
import store  from '@/store'
import { ProductTypes, userOrigin } from '@/utils/constants.ts'
import UsersConfig from '@/views/UsersConfig.vue';

const isClientLoggedIn = async (to: any, from: any, next: Function) => {
    await initialActions();
    const currentHostname = window.location.hostname;

    // if (currentHostname === 'devbbvasuite.wearetesters.com' && !store.getters.isAuthenticated) {
    //     window.location.href = 'https://devsso.wearetesters.com/bbva/login';
    //     return
    // }else
        if(currentHostname === 'devbbvasuite.wearetesters.com' && store.getters.isAuthenticated){
            if(to.matched[0].path === '/auth') {
                return next({ name: 'dashboard' })
            }
        return next()
    }

    if (to.name === 'watmonitor' || to.name === 'testpreview' || to.name === 'WatMonitorV3') return next();
    if (to.matched[0].path === '/auth' && store.getters.isAuthenticated && to.name !== '2fa') return next({ name: 'dashboard' });
    else if (to.matched[0].path === '/app' && !store.getters.isAuthenticated) return next({ name: 'login' });
    return next();
};
  

const canAccessRoute = async (to: any, from: any, next: Function) => {
  if(to.name != 'afterlogin' && VueCookieNext.getCookie('mustChangePassword')) return next({name:'afterlogin', params: { newTabNum: 2 }})
  if(to.name != '2fa' && VueCookieNext.getCookie('needs2FA')) return next({name:'2fa' })
  if((to.name == '2fa' ||
    to.name == 'afterlogin') &&
    (!VueCookieNext.getCookie('mustChangePassword') &&
    !VueCookieNext.getCookie('needs2FA'))) return next({name:'dashboard'})

  return next()
}

const initialActions= async () =>{
  const urlParams = new URLSearchParams(window.location.search)
  if(urlParams.get('authtoken')){
    store.dispatch('setCookieAuthtoken', urlParams.get('authtoken'))
    store.commit('setStateAuthtoken', urlParams.get('authtoken'))
    window.history.replaceState({}, document.title, "/");
  }

  if(store.getters.isAuthenticated){
    store.dispatch('getNotifications')
    await store.dispatch('getClientData')
    await store.dispatch('getCreditsPlan')
  }

  store.dispatch('getProvinces',{country:'es'})
  store.dispatch('getRegions',{country:'es'})
  store.dispatch('getNielsenAreas',{country:'es'})
  store.dispatch('getLovElement',{lovName:'countries'})
  store.dispatch('getLovElement',{lovName:'idiomas'})
  store.dispatch('getLovElement',{lovName:'company_sector'})
}

const routes = [
  { path: '/', redirect:'login' },
  { path: '/ssossuccess', name:'SSOSsuccess', props:true, component: ( ) => import( '@/views/SSOSsuccess.vue' ) },
  
  
  { path: '/auth', component: ( ) => import( '@/views/layouts/AuthView.vue' ),
    children: [
        { path: '/login', name:'login', component: ( ) => import( '@/views/Login.vue' ), beforeEnter:isClientLoggedIn },
        { path: '/forgot-password', name:'forgotPassword', component: ( ) => import( '@/views/ForgotPassword.vue' ), beforeEnter:isClientLoggedIn },
        { path: '/2fa', name:'2fa', props:true, component: ( ) => import( '@/views/2FAPage.vue' ), beforeEnter:[canAccessRoute,isClientLoggedIn] },
    ]
  },
  { path: '/app', component: ( ) => import( '@/views/layouts/View.vue' ), beforeEnter:isClientLoggedIn,
    children: [
      { path: '/login-after', name:'afterlogin', props:true, component: ( ) => import( '@/views/AfterLogin.vue' ), beforeEnter:canAccessRoute },
      { path: '/dashboard', name:'dashboard', component: ( ) => import( '@/views/Dashboard.vue' ), beforeEnter:canAccessRoute },
      { path: '/transactions', name:'transactions', component: ( ) => import( '@/views/Transactions.vue' ), beforeEnter:canAccessRoute},
      { path: '/settings', name:'settings', component: ( ) => import( '@/views/Settings.vue' ), beforeEnter:canAccessRoute },
      { path: '/usersConfig', name:'usersConfig', component: ( ) => import( '@/views/UsersConfig.vue' ), beforeEnter:canAccessRoute },
      { path: '/logs', name:'logs', component: ( ) => import( '@/views/Logs.vue' ), beforeEnter:canAccessRoute },
      { path: '/new-study', name:'newstudy', redirect:'/new-study/step1', component: ( ) => import( '@/views/StudyView.vue' ),props:true, beforeEnter:canAccessRoute,
        children:[
          { path: '/new-study/step1', name:'newstudy.step1', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:0}},
          { path: '/new-study/step1/:id/:testId?', name:'newstudy.step1Edit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:0, isStudyCreated: true}},
          { path: '/new-study/watlab/step2', name:'newstudy.step2Watlab', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1}},
          { path: '/new-study/watlab/step2/:id/:testId', name:'newstudy.step2WatLabEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/watlab/step3/:id/:testId', name:'newstudy.step3WatLab', component: ( ) => import( '@/views/Step3WatLab.vue' ), props:{STEP:2, isStudyCreated: true}},
          { path: '/new-study/watlab/step4/:id/:testId', name:'newstudy.step4WatLab', component: ( ) => import( '@/views/Step4WatLab.vue' ), props:{STEP:3, isStudyCreated: true}},
          { path: '/new-study/survey/step2', name:'newstudy.step2Survey', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1}},
          { path: '/new-study/survey/step2/:id/:testId', name:'newstudy.step2SurveyEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/survey/step3/:id/:testId', name:'newstudy.step3Survey', component: ( ) => import( '@/views/Step3Survey.vue' ), props:{STEP:2, isStudyCreated: true}},
          { path: '/new-study/survey/step4/:id/:testId', name:'newstudy.step4Survey', component: ( ) => import( '@/views/Step4Survey.vue' ), props:{STEP:3, isStudyCreated: true, type: ProductTypes.WAT_SURVEY }},
          { path: '/new-study/watux/step2', name:'newstudy.step2WatUx', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1}},
          { path: '/new-study/watux/step2/:id/:testId', name:'newstudy.step2WatUxEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/watux/step3/:id/:testId', name:'newstudy.step3WatUx', component: ( ) => import( '@/views/Step3WatUx.vue' ), props:{STEP:2, isStudyCreated: true, type: ProductTypes.WAT_UX}},
          { path: '/new-study/watux/step4/:id/:testId', name:'newstudy.step4WatUx', component: ( ) => import( '@/views/Step4Survey.vue' ), props:{STEP:3, isStudyCreated: true, type: ProductTypes.WAT_UX}},
          { path: '/new-study/watuxquanti/step2', name:'newstudy.step2WatUxQuanti', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1}},
          { path: '/new-study/watuxquanti/step2/:id/:testId', name:'newstudy.step2WatUxQuantiEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/watuxquanti/step3/:id/:testId', name:'newstudy.step3WatUxQuanti', component: ( ) => import( '@/views/Step3WatUx.vue' ), props:{STEP:2, isStudyCreated: true, type: ProductTypes.WAT_UX_QUANTI}},
          { path: '/new-study/watuxquanti/step4/:id/:testId', name:'newstudy.step4WatUxQuanti', component: ( ) => import( '@/views/Step4Survey.vue' ), props:{STEP:3, isStudyCreated: true, type: ProductTypes.WAT_UX_QUANTI}},
          { path: '/new-study/clicktest/step2', name:'newstudy.step2ClickTest', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1}},
          { path: '/new-study/clicktest/step2/:id/:testId', name:'newstudy.step2ClickTestEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/clicktest/step3/:id/:testId', name:'newstudy.step3ClickTest', component: ( ) => import( '@/views/Step3WatUx.vue' ), props:{STEP:2, isStudyCreated: true, type: ProductTypes.CLICK_TEST}},
          { path: '/new-study/clicktest/step4/:id/:testId', name:'newstudy.step4ClickTest', component: ( ) => import( '@/views/Step4Survey.vue' ), props:{STEP:3, isStudyCreated: true, type: ProductTypes.CLICK_TEST}},
          { path: '/new-study/watfocus/step2/client', name:'newstudy.step2WatFocusClient', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, usersOrigin: userOrigin.CLIENT}},
          { path: '/new-study/watfocus/step2/wat', name:'newstudy.step2WatFocusWat', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, usersOrigin: userOrigin.WAT}},
          { path: '/new-study/watfocus/step2/:id', name:'newstudy.step2WatFocusEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/watfocus/step3/:id', name:'newstudy.step3WatFocus', component: ( ) => import( '@/views/Step3WatFocus.vue' ), props:{STEP:2, isStudyCreated: true, type: ProductTypes.WAT_FOCUS}},
          { path: '/new-study/watfocus/step4/:id', name:'newstudy.step4WatFocus', component: ( ) => import( '@/views/Step4WatFocus.vue' ), props:{STEP:3, isStudyCreated: true, type: ProductTypes.WAT_FOCUS}},
          { path: '/new-study/watfocus/step5/:id', name:'newstudy.step5WatFocus', component: ( ) => import( '@/views/Step4Survey.vue' ), props:{STEP:4, isStudyCreated: true, type: ProductTypes.WAT_FOCUS}},
          { path: '/new-study/watneuro/step2', name:'newstudy.step2WatNeuro', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1}},
          { path: '/new-study/watneuro/step2/:id', name:'newstudy.step2WatNeuroEdit', component: ( ) => import( '@/views/NewStudy.vue' ), props:{STEP:1, isStudyCreated: true}},
          { path: '/new-study/watneuro/step3/:id/:testId', name:'newstudy.step3WatNeuro', component: ( ) => import( '@/views/Step3WatUx.vue' ), props:{STEP:2, isStudyCreated: true, type: ProductTypes.WAT_NEURO}},
          { path: '/new-study/watneuro/step4/:id/:testId', name:'newstudy.step4WatNeuro', component: ( ) => import( '@/views/Step4Survey.vue' ), props:{STEP:3, isStudyCreated: true, type: ProductTypes.WAT_NEURO}},

        ]
      },
      { path: '/WatFocusConfig/:contractId', name:'watfocusconfig', component: ( ) => import( '@/views/WatFocusConfig.vue' ), beforeEnter:canAccessRoute },
      { path: '/focus/:id/:slotHash', name:'watfocusexecution', component: ( ) => import( '@/views/WatFocusExecution.vue' ), beforeEnter:canAccessRoute },
      { path: '/focus/:id/:slotHash/observer', name:'watfocusexecutionobs', component: ( ) => import( '@/views/WatFocusExecution.vue' ),props:{isObservator:true} },
      { path: '/WatMonitor/:id', name:'watmonitor', props:true, component: ( ) => import( '@/views/WatMonitorNew.vue' ) },
      { path: '/WatNeuroMonitor/:id', name:'watmonitorneuro', props:true, component: ( ) => import( '@/views/WatNeuroMonitor.vue' ) }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
